<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Using the table of thermodynamic properties
        <a
          href="https://openstax.org/books/chemistry-2e/pages/g-standard-thermodynamic-properties-for-selected-substances"
          rel="noopener noreferrer"
          target="_blank"
          >here</a
        >, determine
        <stemble-latex content="$\Delta\text{S}^{\circ}\text{,}$" />
        <stemble-latex content="$\Delta\text{H}^{\circ}\text{, and}$" />
        <stemble-latex content="$\Delta\text{G}^{\circ}$" />
        for each of the reactions shown below:
      </p>

      <p class="mb-2">
        <chemical-latex content="a) MnO2(s) -> Mn(s) + O2(g)" />
      </p>

      <v-simple-table class="mb-10 pl-10" style="width: 600px">
        <thead>
          <tr>
            <th style="font-size: 15px; width: 34%">
              <stemble-latex
                content="$\Delta\text{S}^\circ\text{ (J K}^{-1}\text{mol}^{-1}\text{)}$"
              />
            </th>
            <th style="font-size: 15px; width: 33%">
              <stemble-latex content="$\Delta\text{H}^\circ\text{ (kJ/mol)}$" />
            </th>
            <th style="font-size: 15px; width: 33%">
              <stemble-latex content="$\Delta\text{G}^\circ\text{ (kJ/mol)}$" />
            </th>
          </tr>
          <tr>
            <td>
              <calculation-input v-model="inputs.deltaS1" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.deltaH1" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.deltaG1" :disabled="!allowEditing" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-2">
        <chemical-latex content="b) 2 LiOH(s) + CO2(g) -> Li2CO3(s) + H2O(g)" />
      </p>

      <v-simple-table class="mb-10 pl-10" style="width: 600px">
        <thead>
          <tr>
            <th style="font-size: 15px; width: 34%">
              <stemble-latex
                content="$\Delta\text{S}^\circ\text{ (J K}^{-1}\text{mol}^{-1}\text{)}$"
              />
            </th>
            <th style="font-size: 15px; width: 33%">
              <stemble-latex content="$\Delta\text{H}^\circ\text{ (kJ/mol)}$" />
            </th>
            <th style="font-size: 15px; width: 33%">
              <stemble-latex content="$\Delta\text{G}^\circ\text{ (kJ/mol)}$" />
            </th>
          </tr>
          <tr>
            <td>
              <calculation-input v-model="inputs.deltaS2" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.deltaH2" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.deltaG2" :disabled="!allowEditing" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-2">
        <chemical-latex content="c) CS2(g) + 3Cl2(g) -> CCl4(g) + S2Cl2(g)" />
      </p>

      <v-simple-table class="pl-10 mb-4" style="width: 600px">
        <thead>
          <tr>
            <th style="font-size: 15px; width: 34%">
              <stemble-latex
                content="$\Delta\text{S}^\circ\text{ (J K}^{-1}\text{mol}^{-1}\text{)}$"
              />
            </th>
            <th style="font-size: 15px; width: 33%">
              <stemble-latex content="$\Delta\text{H}^\circ\text{ (kJ/mol)}$" />
            </th>
            <th style="font-size: 15px; width: 33%">
              <stemble-latex content="$\Delta\text{G}^\circ\text{ (kJ/mol)}$" />
            </th>
          </tr>
          <tr>
            <td>
              <calculation-input v-model="inputs.deltaS3" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.deltaH3" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.deltaG3" :disabled="!allowEditing" />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question296',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        deltaG: null,
      },
    };
  },
  computed: {
    qsurr() {
      return this.taskState.getValueBySymbol('qsurr').content;
    },
  },
};
</script>
